import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Process = makeShortcode("Process");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-欢迎来到-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`🌱 欢迎来到 Kernel`}<a parentName="h1" {...{
        "href": "#-%E6%AC%A2%E8%BF%8E%E6%9D%A5%E5%88%B0-kernel",
        "aria-label": " 欢迎来到 kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Kernel 是一个同伴/点对点学习社区，旨在更好地探索真相：在工作、我们与他人联系、以及我们的内心世界中。`}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 发现他人，享受乐趣，自由学习，并获得资助。`}</p>
    </blockquote>
    <p>{`我们的问题是：`}<strong parentName="p">{`如何为所关心的人构建更好的网络`}</strong>{`? `}</p>
    <p>{`你的问题也许是：`}<strong parentName="p">{`我应该怎样参与进来，从而尊重自己的天赋与真实的内心？`}</strong>{` `}</p>
    <p>{`我们的答案：埋下一颗教育性社区的种子，在那里，人们通过亲身体验来`}<strong parentName="p">{`建立信任`}</strong>{`， 通过共同学习以用心创造。 `}</p>
    <h2 {...{
      "id": "但，kernel-是什么？",
      "style": {
        "position": "relative"
      }
    }}>{`但，Kernel 是什么？`}<a parentName="h2" {...{
        "href": "#%E4%BD%86%EF%BC%8Ckernel-%E6%98%AF%E4%BB%80%E4%B9%88%EF%BC%9F",
        "aria-label": "但，kernel 是什么？ permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Kernel 是在由 250 个很棒的人组成的“区块”中的、为期 8 周的对话，它致力于连接创造力与关怀。我们想要改变人们对”做贡献“的理解，以及我们所做贡献的项目。Kernel 关于谦卑、诚实、感激和有意向（intentional）的服务。`}</p>
    <Process mdxType="Process">
      <p>{`我们谦卑；因为通过我们的好奇心，我们的创造力，我们的苦痛，我们的欢乐——我们得知且亲身展示了每一个孤立个体所能了解的多么少。`}</p>
      <p>{`我们诚实；因为这是最勇敢的生活方式；是让头脑直达心灵，且在两者间建立永恒连接的最简单的路。`}</p>
      <p>{`我们感激，因为别无选择。任何经过审视过的生活总会带来超越理性所能衡量的感激之情。`}</p>
      <p>{`我们的目的是去劳作（serve），因为它——一次又一次——带我们回归谦卑。`}</p>
      <p>{`我们耐心，因为埋下种子还不够：只有在对的季节，核（Kernel）才会破土而出，长成森林。`}</p>
    </Process>
    <h2 {...{
      "id": "-转变，而非信息",
      "style": {
        "position": "relative"
      }
    }}>{`✨ 转变，而非信息`}<a parentName="h2" {...{
        "href": "#-%E8%BD%AC%E5%8F%98%EF%BC%8C%E8%80%8C%E9%9D%9E%E4%BF%A1%E6%81%AF",
        "aria-label": " 转变，而非信息 permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Kernel 不会用信息淹没你，而是通过与同伴的反复互动，来逐步构建更好的网络所需的内在和外在模式。这些模式包括谦卑的决断、细致的洞察、同情式的批评、理性的互惠、以及其它涵盖人类意识全部光谱的、互补的二元对立。`}</p>
    <p>{`花时间逐一浏览每个模块。放下手中的事，呼吸，专注一个小时；明天或下周，当你有空做同样的事时，再回来。第一次，我们书写人类历史的工具是基本共享并基于共识的。此刻去暂停、反思、并将注意力转向那些会培养我们——所有的和每一个我们——共同生活的能力的激励类型。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      